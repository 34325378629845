:root {
    --shadow-color: #004c3f16;
    --shadow-color-light: #cefff7;
}

body {
    background: white;
}

html {
    overflow-x: hidden;
}

.mouse-cursor {
    position: fixed;
    left: 0;
    top: 0;
    pointer-events: none;
    border-radius: 50%;
    -webkit-transform: translateZ(0);
    transform: translateZ(0);
    visibility: hidden
}

.cursor-inner {
    margin-left: -3px;
    margin-top: -3px;
    width: 6px;
    height: 6px;
    z-index: 100;
    background-color: #FE5D14;
    -webkit-transition: width .3s ease-in-out, height .3s ease-in-out, margin .3s ease-in-out, opacity .3s ease-in-out;
    transition: width .3s ease-in-out, height .3s ease-in-out, margin .3s ease-in-out, opacity .3s ease-in-out
}

.cursor-inner.cursor-hover {
    margin-left: -40px;
    margin-top: -40px;
    width: 80px;
    height: 80px;
    background-color: #FE5D14;
    opacity: .3
}

.cursor-outer {
    margin-left: -15px;
    margin-top: -15px;
    width: 30px;
    height: 30px;
    border: 2px solid #FE5D14;
    -webkit-box-sizing: border-box;
    box-sizing: border-box;
    z-index: 100;
    opacity: .5;
    -webkit-transition: all .08s ease-out;
    transition: all .08s ease-out;

}

.cursor-outer.cursor-hover {
    opacity: 0
}

.about-shape {
    border: 2px dashed #004C3F;
    border-radius: 50%;
    top: 63px;
    position: absolute;
    left: 185px;
}

.about-shape:before {
    content: "";
    display: block;
    width: 15px;
    height: 15px;
    background: #028835;
    border-radius: 50%;
    position: absolute;
    left: calc(50% - 7.5px);
    top: -7.5px;
}

.about-shape2 {
    border: 2px dashed #004C3F;
    border-radius: 50%;
    top: 45px;
    position: absolute;
    left: 115px;
}

.about-shape2:before {
    content: "";
    display: block;
    width: 15px;
    height: 15px;
    background: #028835;
    border-radius: 50%;
    position: absolute;
    left: calc(50% - 7.5px);
    top: -7.5px;
}

.theme-container {
    /*1290*/
    width: 1330px;
    padding: 0 20px;
}

.hero-area {
    height: calc(100vh - 140px);
}

.hero-image-1 {
    background-position: 100% center;
}

.hero-area-2 {
    background-image: url(./../img/hero-img-thumb.png);
    background-repeat: no-repeat;
    background-size: cover;
}

.service-area {
    background-image: url(../img/service-area.png);
    background-size: cover;
    background-repeat: no-repeat;
    min-height: 828px;
}

.blog-area .blog-item:hover {
    box-shadow: 0px 16px 52px rgba(0, 0, 0, 0.07);
}

.insu-swiper .swiper-pagination {
    top: auto;
    bottom: -60px;
}

.insu-swiper .swiper-pagination .swiper-pagination-bullet {
    width: 40px;
    height: 3px;
    border-radius: 0;
    background: #E3E3E3;
}

.insu-swiper .swiper-pagination .swiper-pagination-bullet.swiper-pagination-bullet-active {
    background: #028835;
}

.faq-main-wrapper {
    box-shadow: 0px 4.8px 24.4px -6px rgba(19, 16, 34, 0.1), 0px 4px 13px -2px rgba(19, 16, 34, 0.06);
}

.fun-fact-area .faq-wrapper {
    top: calc(100% + 100px);
}

.fun-fact-area .faq-item .faq-body,
.about-article-area .faq-item .faq-body {
    height: 0;
    padding: 0 20px;
    display: flex;
    align-items: center;
    overflow: hidden;
    transition: all 0.5s ease-in-out;
}

.faq-section-main-wrapper .faq-item .faq-body {
    max-height: 0;
    display: flex;
    align-items: center;
    overflow: hidden;
    transition: all 0.5s ease-in-out;
    padding: 0 20px;
}

.faq-body.w-full.bg-secondary.active {
    padding: 20px;
    max-height: 300px;
}

.fun-fact-area .faq-item:focus-within .faq-body {
    height: 130px;
}

.faq-item .faq-btn {
    color: #004C3F;
}

.fun-fact-area .faq-item:focus-within .faq-btn {
    color: white;
    background: #028835;
}

.faq-fun-fact-area-two .faq-item:focus-within .faq-btn {
    color: #004C3F !important;
    background: transparent !important;
}

.progress-step-wrapper {
    background-image: url(./../img/progress-bg.svg);
    background-size: cover;
    background-repeat: no-repeat
}

.progress-step-wrapper .item {
    box-shadow: 0 16px 52px rgba(0, 0, 0, 0.07);
}

.progress-step-wrapper .item .counter {
    font-size: 40px;
    color: #004C3F;
    -webkit-text-fill-color: white;
    /* Will override color (regardless of order) */
    -webkit-text-stroke-width: 1px;
    -webkit-text-stroke-color: #004C3F;
    opacity: 0.3;
}

.financ-section .swiper-button-prev:after,
.financ-section .swiper-rtl .swiper-button-next:after {
    content: '';
}

.financ-section .swiper-button-next:after,
.financ-section .swiper-rtl .swiper-button-prev:after {
    content: '';
}

.financ-section .swiper-button-prev,
.financ-section .swiper-rtl .swiper-button-next {
    position: unset;
}

.financ-section .swiper-button-next,
.financ-section .swiper-rtl .swiper-button-prev {
    position: unset;
}

.financ-swiper .swiper-pagination {
    top: auto;
    bottom: -60px;
}

.financ-swiper .swiper-pagination .swiper-pagination-bullet {
    width: 12px;
    height: 12px;
    border-radius: 6px;
    background: #E3E3E3;
}

.financ-swiper .swiper-pagination .swiper-pagination-bullet.swiper-pagination-bullet-active {
    background: #028835;
}

.gear {
    animation: rotate 110s infinite linear;
    transform-origin: center;
    transform-box: fill-box;
}

.gear-2 {
    animation: rotate-2 80s infinite linear;
    transform-origin: center;
    transform-box: fill-box;
}

.footer-shape {
    right: -302px;
    top: -160px;
}

@keyframes rotate {
    0% {
        transform: rotate(0deg);
    }

    100% {
        transform: rotate(360deg);
    }
}

@keyframes rotate-2 {
    0% {
        transform: rotate(0deg);
    }

    100% {
        transform: rotate(-120deg);
    }
}

.hero-area-2 .shape:nth-child(1) img {
    transform: translateX(0px);
    animation: shape-shake 3s infinite ease;
}

.hero-area-2 .shape:nth-child(2) img {
    transform: translateX(0px);
    animation: shape-shake 3s infinite ease 1s;
}

.hero-area-2 .shape:nth-child(3) img {
    transform: translateX(0px);
    animation: shape-shake 3s infinite ease;
}

.hero-area-2 .shape:nth-child(4) img {
    transform: translateX(0px);
    animation: shape-shake 3s infinite ease 0.5s;
}

@keyframes shape-shake {
    0% {
        transform: translateY(0px);
    }

    50% {
        transform: translateY(10px);
    }

    100% {
        transform: translateY(0px);
    }
}

@-webkit-keyframes shape-shake {
    0% {
        -webkit-transform: translateY(0px);
    }

    50% {
        -webkit-transform: translateY(10px);
    }

    100% {
        -webkit-transform: translateY(0px);
    }
}

.safety-section .blog-content {
    display: none;
    animation: tabFade 1s;
}

.safety-section .blog-content.active {
    display: block;
}

@keyframes tabFade {
    0% {
        opacity: 0;
    }

    100% {
        opacity: 1;
    }
}

.fun-fact-area-two {
    background-image: url(./../img/fun-fact-bg-2.png);
    background-size: cover;
    background-repeat: no-repeat
}

.about-us-page-wrapper .process-section-two .item .circle-shape {
    background: #FBF7ED;
    transition: all 0.5s ease-in-out;
}

.process-section-two .item .circle-shape {
    background: #E6EDEC;
    transition: all 0.5s ease-in-out;
}

.footer-wrapper-two .discover-area {
    background-image: url(./../img/discover-bg.png);
    background-size: cover;
    background-repeat: no-repeat
}

.page-title-wrapper {
    background-image: url(./../img/inner-page-banner.png);
    background-size: cover;
    background-repeat: no-repeat
}

.mini-contact {
    background-image: url(./../img/min-contact-bg.png);
    background-size: cover;
    background-repeat: no-repeat
}

.breadcrumb-wrapper .breadcrumb-item:last-child .ico {
    display: none;
}

.testimonial-section-two .swiper-pagination {
    top: auto;
    bottom: -60px;
}

.testimonial-section-two .swiper-pagination .swiper-pagination-bullet {
    width: 40px;
    height: 3px;
    border-radius: 6px;
    background: #E3E3E3;
}

.testimonial-section-two .swiper-pagination .swiper-pagination-bullet.swiper-pagination-bullet-active {
    background: #028835;
}


.related-post-slider .swiper-button-prev:after,
.related-post-slider .swiper-rtl .swiper-button-next:after {
    content: '';
}

.related-post-slider .swiper-button-next:after,
.related-post-slider .swiper-rtl .swiper-button-prev:after {
    content: '';
}

.related-post-slider .swiper-button-prev,
.related-post-slider .swiper-rtl .swiper-button-next {
    position: unset;
}

.related-post-slider .swiper-button-next,
.related-post-slider .swiper-rtl .swiper-button-prev {
    position: unset;
}

.faq-page-wrapper .faq-item .faq-body {
    display: none;
}

.faq-page-wrapper .faq-item:nth-child(1) .faq-body {
    display: block;
}

.faq-page-wrapper .faq-item:focus-within .faq-body {
    display: block;
}


/*====================*/
.navigation-wrapper.sticky {
    position: fixed;
    left: 0;
    top: 0;
    right: 0;
    z-index: 810;
    background: #fff;
    box-shadow: 0 15px 30px 0 rgba(0, 0, 0, .058823529411764705);
    padding-top: 20px;
    padding-bottom: 20px;
    -webkit-animation: sticky 1.2s;
    animation: sticky 1.2s;
}

.navigation-wrapper.navigation-wrapper-two-two.sticky {
    background: #004C3F;
}

@keyframes sticky {
    0% {
        top: -200px;
    }

    100% {
        top: 0;
    }

}

.nav-wrapper ul li {
    position: relative;
}

.nav-wrapper.mobile-nav-wrapper ul li a {
    width: 100%;
    display: flex;
    justify-content: space-between;
    font-size: 18px;
    padding: 10px 0;
    border-bottom: 1px solid rgb(231 231 231)
}

.nav-wrapper.mobile-nav-wrapper ul li ul {
    margin-left: 16px;
}

.nav-wrapper ul li ul {
    position: absolute;
    left: -10px;
    min-width: 200px;
    border-radius: 10px;
    background: white;
    padding: 10px 0;
    box-shadow: 11px 10px 38px 0 rgba(46, 63, 99, .15);
    display: none;
    z-index: 99;

}

.nav-wrapper.mobile-nav-wrapper ul li ul {
    position: unset;
    left: -10px;
    display: none;
    z-index: 99;
    box-shadow: none;
    padding: 0;
    margin-top: 10px;

}

.nav-wrapper ul li:hover ul {
    display: block;
    animation: tabFade 0.5s;
}

.nav-wrapper ul li ul li a {
    padding: 5px 20px;
    color: #004C3F;
    transition: all 0.5s ease-in-out;
}

.nav-wrapper ul li ul li:hover a {
    padding-left: 30px;
    color: #004C3F;


}

.nav-wrapper ul li ul li {
    position: relative;

}

.nav-wrapper ul li ul li:before {
    content: '';
    display: block;
    width: 0;
    height: 3px;
    background: #028835;
    position: absolute;
    left: 0;
    top: calc(50% - 2px);
    transition: all 0.5s ease-in-out;


}

.nav-wrapper ul li ul li:hover:before {
    width: 20px;
}

/*====================*/
.nav-wrapper.nav-wrapper-2 ul li {
    position: relative;
}

.nav-wrapper.nav-wrapper-2 ul li ul {
    position: absolute;
    left: -10px;
    min-width: 200px;
    border-radius: 10px;
    background: white;
    padding: 10px 0;
    box-shadow: 11px 10px 38px 0 rgba(46, 63, 99, .15);
    display: none;

}

.nav-wrapper.nav-wrapper-2 ul li:hover ul {
    display: block;

}

.nav-wrapper.nav-wrapper-2 ul li ul li a {
    padding: 5px 20px;
    color: #004C3F;
    transition: all 0.5s ease-in-out;
}

.nav-wrapper.nav-wrapper-2 ul li ul li:hover a {
    padding-left: 30px;
    color: #004C3F;


}

.nav-wrapper.nav-wrapper-2 ul li ul li {
    position: relative;

}

.nav-wrapper.nav-wrapper-2 ul li ul li:before {
    content: '';
    display: block;
    width: 0;
    height: 3px;
    background: #028835;
    position: absolute;
    left: 0;
    top: calc(50% - 2px);
    transition: all 0.5s ease-in-out;


}

.nav-wrapper.nav-wrapper-2 ul li ul li:hover:before {
    width: 20px;
}

/*=====mobile=====*/
.nav-wrapper .mobile-drawer .mobile-wid {
    left: -350px;
    transition: all .5s linear;
}

.nav-wrapper .mobile-drawer.active .mobile-wid {
    left: 0;
}

.nav-wrapper .mobile-drawer .drawer-away {
    display: none;
    animation: tabFade 0.5s;
}

.nav-wrapper .mobile-drawer.active .drawer-away {
    display: block;
}

/*nav end*/

.search-bar-wrapper .search-form {
    display: none;
    animation: tabFade 0.5s;
}

.search-bar-wrapper:focus-within .search-form {
    display: block;
}

.faq-section-main-wrapper .faq-two {
    box-shadow: 0 4px 24px -6px rgba(19, 16, 34, 0.1),
        0 4px 13px -2px rgba(19, 16, 34, 0.06)
}

/*popv component*/
.popv-main-wrapper {
    display: none;
}

.popv-main-wrapper.active {
    display: block;
}

.popv-bg {
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 1042;
    overflow: hidden;
    position: fixed;
    background: #0b0b0b;
    opacity: 0.8;
}

.popv-wrap {
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 1043;
    position: fixed;
    outline: none !important;
    -webkit-backface-visibility: hidden;
}

.popv-container {
    text-align: center;
    position: absolute;
    width: 100%;
    height: 100%;
    left: 0;
    top: 0;
    padding: 0 8px;
    box-sizing: border-box;
}

.popv-container:before {
    content: '';
    display: inline-block;
    height: 100%;
    vertical-align: middle;
}

.popv-align-top .popv-container:before {
    display: none;
}

.popv-content {
    position: relative;
    display: inline-block;
    vertical-align: middle;
    margin: 0 auto;
    text-align: left;
    z-index: 1045;
}

.popv-inline-holder .popv-content,
.popv-ajax-holder .popv-content {
    width: 100%;
    cursor: auto;
}

.popv-ajax-cur {
    cursor: progress;
}

.popv-zoom-out-cur,
.popv-zoom-out-cur .popv-image-holder .popv-close {
    cursor: -moz-zoom-out;
    cursor: -webkit-zoom-out;
    cursor: zoom-out;
}

.popv-zoom {
    cursor: pointer;
    cursor: -webkit-zoom-in;
    cursor: -moz-zoom-in;
    cursor: zoom-in;
}

.popv-auto-cursor .popv-content {
    cursor: auto;
}

.popv-close,
.popv-arrow,
.popv-preloader,
.popv-counter {
    -webkit-user-select: none;
    -moz-user-select: none;
    user-select: none;
}

.popv-loading.popv-figure {
    display: none;
}

.popv-hide {
    display: none !important;
}

.popv-preloader {
    color: #CCC;
    position: absolute;
    top: 50%;
    width: auto;
    text-align: center;
    margin-top: -0.8em;
    left: 8px;
    right: 8px;
    z-index: 1044;
}

.popv-preloader a {
    color: #CCC;
}

.popv-preloader a:hover {
    color: #FFF;
}

.popv-s-ready .popv-preloader {
    display: none;
}

.popv-s-error .popv-content {
    display: none;
}

button.popv-close,
button.popv-arrow {
    overflow: visible;
    cursor: pointer;
    background: transparent;
    border: 0;
    -webkit-appearance: none;
    display: block;
    outline: none;
    padding: 0;
    z-index: 1046;
    box-shadow: none;
    touch-action: manipulation;
}

button::-moz-focus-inner {
    padding: 0;
    border: 0;
}

.popv-close {
    width: 44px;
    height: 44px;
    line-height: 44px;
    position: absolute;
    right: 0;
    top: 0;
    text-decoration: none;
    text-align: center;
    opacity: 0.65;
    padding: 0 0 18px 10px;
    color: #FFF;
    font-style: normal;
    font-size: 28px;
    font-family: Arial, Baskerville, monospace;
}

.popv-close:hover,
.popv-close:focus {
    opacity: 1;
}

.popv-close:active {
    top: 1px;
}

.popv-close-btn-in .popv-close {
    color: #333;
}

.popv-image-holder .popv-close,
.popv-iframe-holder .popv-close {
    color: #FFF;
    right: -6px;
    text-align: right;
    padding-right: 6px;
    width: 100%;
}

.popv-counter {
    position: absolute;
    top: 0;
    right: 0;
    color: #CCC;
    font-size: 12px;
    line-height: 18px;
    white-space: nowrap;
}

.popv-arrow {
    position: absolute;
    opacity: 0.65;
    margin: 0;
    top: 50%;
    margin-top: -55px;
    padding: 0;
    width: 90px;
    height: 110px;
    -webkit-tap-highlight-color: transparent;
}

.popv-arrow:active {
    margin-top: -54px;
}

.popv-arrow:hover,
.popv-arrow:focus {
    opacity: 1;
}

.popv-arrow:before,
.popv-arrow:after {
    content: '';
    display: block;
    width: 0;
    height: 0;
    position: absolute;
    left: 0;
    top: 0;
    margin-top: 35px;
    margin-left: 35px;
    border: medium inset transparent;
}

.popv-arrow:after {
    border-top-width: 13px;
    border-bottom-width: 13px;
    top: 8px;
}

.popv-arrow:before {
    border-top-width: 21px;
    border-bottom-width: 21px;
    opacity: 0.7;
}

.popv-arrow-left {
    left: 0;
}

.popv-arrow-left:after {
    border-right: 17px solid #FFF;
    margin-left: 31px;
}

.popv-arrow-left:before {
    margin-left: 25px;
    border-right: 27px solid #3F3F3F;
}

.popv-arrow-right {
    right: 0;
}

.popv-arrow-right:after {
    border-left: 17px solid #FFF;
    margin-left: 39px;
}

.popv-arrow-right:before {
    border-left: 27px solid #3F3F3F;
}

.popv-iframe-holder {
    padding-top: 40px;
    padding-bottom: 40px;
}

.popv-iframe-holder .popv-content {
    line-height: 0;
    width: 100%;
    max-width: 900px;
}

.popv-iframe-holder .popv-close {
    top: -40px;
}

.popv-iframe-scaler {
    width: 100%;
    height: 0;
    overflow: hidden;
    padding-top: 56.25%;
}

.popv-iframe-scaler iframe {
    position: absolute;
    display: block;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    box-shadow: 0 0 8px rgba(0, 0, 0, 0.6);
    background: #000;
}

/* Main image in popup */
img.popv-img {
    width: auto;
    max-width: 100%;
    height: auto;
    display: block;
    line-height: 0;
    box-sizing: border-box;
    padding: 40px 0 40px;
    margin: 0 auto;
}

/* The shadow behind the image */
.popv-figure {
    line-height: 0;
}

.popv-figure:after {
    content: '';
    position: absolute;
    left: 0;
    top: 40px;
    bottom: 40px;
    display: block;
    right: 0;
    width: auto;
    height: auto;
    z-index: -1;
    box-shadow: 0 0 8px rgba(0, 0, 0, 0.6);
    background: #444;
}

.popv-figure small {
    color: #BDBDBD;
    display: block;
    font-size: 12px;
    line-height: 14px;
}

.popv-figure figure {
    margin: 0;
}

.popv-bottom-bar {
    margin-top: -36px;
    position: absolute;
    top: 100%;
    left: 0;
    width: 100%;
    cursor: auto;
}

.popv-title {
    text-align: left;
    line-height: 18px;
    color: #F3F3F3;
    word-wrap: break-word;
    padding-right: 36px;
}

.popv-image-holder .popv-content {
    max-width: 100%;
}

.popv-gallery .popv-image-holder .popv-figure {
    cursor: pointer;
}

@keyframes tabFade {
    0% {
        opacity: 0;
    }

    100% {
        opacity: 1;
    }
}

.shake {
    animation: shake 5000ms infinite;
}

@keyframes shake {
    0% {
        transform: rotate(0deg);
    }

    8.0% {
        transform: rotate(0deg);
    }

    12.0% {
        transform: rotate(42deg);
    }

    16.0% {
        transform: rotate(-35deg);
    }

    20.0% {
        transform: rotate(0deg);
    }

    23.0% {
        transform: rotate(28deg);
    }

    26.0% {
        transform: rotate(-20deg);
    }

    29.0% {
        transform: rotate(0deg);
    }

    31.0% {
        transform: rotate(16deg);
    }

    33.0% {
        transform: rotate(-12deg);
    }

    35.0% {
        transform: rotate(0deg);
    }

    37.0% {
        transform: rotate(-6deg);
    }

    39.0% {
        transform: rotate(0deg);
    }

    40.0% {
        transform: rotate(6deg);
    }

    44.0% {
        transform: rotate(-3deg);
    }

    49.0% {
        transform: rotate(2deg);
    }

    55.0% {
        transform: rotate(0deg);
    }

    62.0% {
        transform: rotate(1deg);
    }

    70.0% {
        transform: rotate(0deg);
    }
}

.button-outer-circle,
.button-outer-circle {
    background: hsl(133.4deg 73.02% 15.02% / 20%);
    border-radius: 50%;
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    height: 100%;
    width: 100%;
}

.has-scale-animation,
.has-delay-short {
    -webkit-animation: smallScale 3s infinite;
    animation: smallScale 3s infinite;
}

.has-delay-short {
    -webkit-animation-delay: .5s;
    animation-delay: .5s;
}

@keyframes smallScale {
    0% {
        transform: scale(1);
        opacity: 1;
    }

    100% {
        transform: scale(1.5);
        opacity: 0;
    }

}

.glow {
    animation: neon 3s infinite;
    font-size: 70px;
    padding: 45px 0px;
    text-align: center;
}

@keyframes neon {
    0% {
        text-shadow: -1px -1px 1px var(--shadow-color-light), -1px 1px 1px var(--shadow-color-light), 1px -1px 1px var(--shadow-color-light), 1px 1px 1px var(--shadow-color-light),
            0 0 3px var(--shadow-color-light), 0 0 10px var(--shadow-color-light), 0 0 20px var(--shadow-color-light),
            0 0 30px var(--shadow-color), 0 0 40px var(--shadow-color), 0 0 50px var(--shadow-color), 0 0 70px var(--shadow-color), 0 0 100px var(--shadow-color), 0 0 200px var(--shadow-color);
    }

    50% {
        text-shadow: -1px -1px 1px var(--shadow-color-light), -1px 1px 1px var(--shadow-color-light), 1px -1px 1px var(--shadow-color-light), 1px 1px 1px var(--shadow-color-light),
            0 0 5px var(--shadow-color-light), 0 0 15px var(--shadow-color-light), 0 0 25px var(--shadow-color-light),
            0 0 40px var(--shadow-color), 0 0 50px var(--shadow-color), 0 0 60px var(--shadow-color), 0 0 80px var(--shadow-color), 0 0 110px var(--shadow-color), 0 0 210px var(--shadow-color);
    }

    100% {
        text-shadow: -1px -1px 1px var(--shadow-color-light), -1px 1px 1px var(--shadow-color-light), 1px -1px 1px var(--shadow-color-light), 1px 1px 1px var(--shadow-color-light),
            0 0 3px var(--shadow-color-light), 0 0 10px var(--shadow-color-light), 0 0 20px var(--shadow-color-light),
            0 0 30px var(--shadow-color), 0 0 40px var(--shadow-color), 0 0 50px var(--shadow-color), 0 0 70px var(--shadow-color), 0 0 100px var(--shadow-color), 0 0 200px var(--shadow-color);
    }
}

/*responsive*/

@media (max-width: 1536px) {}

@media (max-width: 1279px) {
    .adPage .about-shape2 {
        left: calc(4vw * 2);
        height: 385px;
        width: 385px;
    }
}

@media (max-width: 1024px) {
    .hero-area {
        height: 600px;
    }

    .theme-container {
        /*1290*/
        width: auto;
        padding: 0, 20px;
    }
}

@media (max-width: 1023px) {
    .adPage {
        text-align: center !important;
    }

    .silhoutte {
        max-width: fit-content;
    }

    .adPage .max-w-4xl {
        max-width: 100vw !important;
    }

    .adPage .thumbnail-area {
        display: none !important;
        justify-content: flex-end;
    }

    .adPage .clickBait {
        display: none;
    }
}

@media (max-width: 768px) {
    .hero-image-1 {
        background-position: 70% center;
    }
}

@media (max-width: 640px) {
    .hero-image-1 {
        background-position: 50% center;
    }

    .faq-section-main-wrapper .faq-two {
        box-shadow: none
    }

    .about-shape {
        top: 50px;
        left: 100px;
    }

    .about-shape2 {
        top: 50px;
        left: 100px;
    }
}

@media (max-width: 320px) {
    html {
        overflow-x: hidden;
    }
}

@media (max-width: 716px) {
    .discover-area .headline-default {
        font-size: 30px;
        line-height: 45px;
    }

    .discover-area {
        height: 220px !important;
        text-align: center;
    }
}

/*responsive end*/